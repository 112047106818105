import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/saleitem', data);
}

export function edit(data) {
  return putRequest(`/saleitem/${data.id}`, data)
}

export function get(id) {
  return getRequest(`/saleitem/${id}`);
}

export function getAll() {
  return postRequest(
    '/saleitem/1',
    {
      "currentPage": 1,
      "pageSize": 8008135,
    },
  )
    .then(({ saleItems }) => saleItems);
}

export function remove(id) {
  return deleteRequest(`/saleitem/${id}`);
}
