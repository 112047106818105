<template>
  <SaleCard
    description="Klik op de button om een nieuwsitem toe te voegen aan de App."
    featured
    :image="require('@/assets/img/events/meeting.webp')"
    name="Aanbieding toevoegen"
  />

  <SaleCard
    v-for="item in saleItems"
    :key="item.id"
    :description="item.description"
    :sale-item-id="item.id"
    :image="item.visualImage"
    :name="item.title"
    :publish-date-time="item.publishDate"
    :reaction-count="item.reactionCount"
  />
</template>

<script>
import { getAll } from '@/api/providers/sale';
import SaleCard from '@/components/Sale/Card.vue';

export default {
  name: 'SaleIndex',

  components: {
    SaleCard,
  },

  data: () => ({
    saleItems: [],
  }),

  mounted() {
    this.loadSaleItems();
  },

  methods: {
    loadSaleItems() {
      getAll()
        .then((saleItems) => {
          this.saleItems = saleItems
        })
        .catch((error) => { if(error.default !== undefined) error.default() });
    },
  },
};
</script>
